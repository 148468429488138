import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { Grid, Card } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ExampleImage from "../components/DataGatsbyImage"
import Layout from "../components/layout"
import clsx from "clsx"
import Seo from "../components/SEO/seo"
import Buscador from "../components/sections/BuscadorCS"
import Breadcrums from "../components/Breadcrumbs/Breadcrumbs"
import ButtonsCarr from "../components/ButtonCarr/Buttons"
const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    position: "relative",
    borderRadius: 12,
    border: "1px solid rgba(0,0,0,0.17)",
    transition: "border 250ms ease-in-out",
    "&:hover": {
      border: "1px solid rgba(var(--primary), 1)",
    },
  },
  customDescription: {
    lineHeight: '1.2',
    maxHeight: '3.6em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    height: '3.6em',
  },
  cardSubcategorias: {
    transition: "all 400ms ease-in-out",
    borderTop: "2px solid black",
    "& .icon": {
      fontSize: 64,
    },

    "&:hover": {
      transform: "translateY(-8px)",

      borderTop: "2px solid rgba(var(--primary), 1)",
      "& .icon": {
        color: "rgba(var(--primary),1)",
      },
    },
  },
  intro: {
    padding: "100px 0 0 !important",
    overflow: "visible !important",

    [theme.breakpoints.down("sm")]: {
      padding: "100px 0 0 !important",
    },
  },
}))

const Categoria = ({ data }) => {
  const classes = useStyles()
  let { allAllproductosJson } = data
  let { Segmento, Categoria, SeoCategoria, SeoImagen } = allAllproductosJson
  const productCategory = allAllproductosJson.edges.map(e => e.node)
  const [productFiltrado, setproductFiltrado] = useState([])

  const handleProducto = (prod) => {
    setproductFiltrado(prod)
  }
  return (
    <>
      <Layout>
        <section className={clsx("section ", classes.intro)} id="courseList1">
          <div className="container">
            <Seo
              link={
                "https://www.lgrepsa.com/categoria/" +
                Categoria[0] +
                "/"
              }
              description={
                "Disdelsa Guatemala " +
                SeoCategoria[0]
              }
              keywords={
                "Disdelsa " + SeoCategoria[0]
              }
              title={
                SeoCategoria[0] + " | Disdelsa "
              }
              image={
                "https://lgrepsa.com/imagenes/productos/" +
                SeoImagen[0]
              }
            />
            <div className="mb-4 text-center mx-auto">
              <Breadcrums title="Inicio" seccion={Segmento[0]} SeccionCategoria={Categoria[0]} />
            </div>
            <div className="mb-8 text-center mx-auto">
              <Buscador handleProducto={handleProducto} products={productCategory} />
              <div className="mb-16">
                <Grid container spacing={1}>
                  <Grid container md={12} sm={12} xs={12} spacing={1}>
                    {productFiltrado.length > 0 ? productFiltrado.map((item, ind) => (
                      <Grid key={ind} item md={2} sm={12} xs={12}>
                        <Card className={`relative h-full card ${classes.card}`}>
                          <Link
                            to={`/producto/${item.ItemCodeAux}/`}
                            style={{
                              cursor: "hand",
                              background: "white",
                              textDecoration: "none",
                            }}
                          >
                            <div style={{ display: "inline-block" }}>
                              <ExampleImage
                                image={item.Imagen}
                                width={100}
                                height={190}
                              />
                            </div>
                            <div className="text-left p-4">
                              <p className={clsx("mt-0 mb-0 text-12 font-semibold text-gray", classes.customDescription)}>
                                {item.IdProducto} <br />
                                {item.Descripcion}
                              </p>
                            </div>
                          </Link>
                          <div className="px-2 py-2">
                            <Grid
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                            >
                              <Link
                                to={`/producto/${item.ItemCodeAux}/`}
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "none",
                                  color: "#FFF",
                                  padding: '8px 16px',
                                  borderRadius: '4px',
                                  border: `1px solid #fff`,
                                  textAlign: 'center',
                                  background: "#fff",
                                }}
                              >
                                Cotizar
                              </Link>
                            </Grid>
                          </div>
                        </Card>
                      </Grid>
                    )) : productCategory.map((item, ind) => (
                      <Grid key={ind} item md={2} sm={12} xs={12}>
                        <Card className={`relative h-full card ${classes.card}`}>
                          <Link
                            to={`/producto/${item.ItemCodeAux}/`}
                            style={{
                              cursor: "hand",
                              background: "white",
                              textDecoration: "none",
                            }}
                          >
                            <div style={{ display: "inline-block" }}>
                              <ExampleImage
                                image={item.Imagen}
                                width={190}
                                height={190}
                              />
                            </div>
                            <div className="text-left p-4">
                              <p className={clsx("mt-0 mb-0 text-12 font-semibold text-gray", classes.customDescription)}>
                                {item.IdProducto} <br />
                                {item.Descripcion}
                              </p>
                            </div>
                          </Link>
                          <div className="px-2 py-2">
                            <Grid
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                            >
                              <Link
                                to={`/producto/${item.ItemCodeAux}/`}
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "none",
                                  color: "#fff",
                                  padding: '8px 16px',
                                  borderRadius: '4px',
                                  border: `1px solid #3f51b5`,
                                  textAlign: 'center',
                                  background: "#3f51b5",
                                }}
                              >
                                Cotizar
                              </Link>
                            </Grid>
                          </div>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Categoria

export const pageQuery = graphql`
  query($categoria: String!) {
    allAllproductosJson(filter: { CategoriaAux: { eq: $categoria } }) {
      edges {
        node {
          id
          IdRelacion
          BaseFardo
          BaseUnidad
          BaseDefault
          ItemCodeAux
          IdProducto
          Descripcion
          Categoria
          Imagen
          Marca
          Unidad
          Fardo
          IdCategoria
        }
      }
      subcategoriaLista: distinct(field: SubCategoriaAux)
      Segmento:distinct(field: SegmentoAux)
      Categoria:distinct(field: CategoriaAux)
      SeoCategoria:distinct(field: Categoria)
      SeoImagen:distinct(field: Imagen)
    }
    segmentosFilter: allMenuJsonJson {
      edges {
        node {
          SegmentoAux
          Categorias {
            NombreCategoria
            NombreCategoriaAux
          }
        }
      }
    }    
  }
`
